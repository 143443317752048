import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { fetchFeatureFlags } from 'state/ducks/featureFlags/operations';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import FilterDrawer, { DEFAULT_SELECTED_FILTERS } from '../Filters';
import PageLayout from '../../components/PageLayout';
import CallToActionBar from '../CallToActionBar';
import BatchActionBar from '../BatchActionBar';
import JobTable from '../../components/JobTable/JobTable';
import Drawers from './components/Drawers';
import { setStaffingWorkRowsPerPage } from '../../state/ducks/staffingWorkPage/actions';

function HomeContainer() {
  const gridApiRef = useGridApiRef();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchFeatureFlags([
        'showAutoStaff',
        'showSingleModeInlineEdit',
        'auto_staff_beyond_home_store',
      ])
    );
  }, []);

  return (
    <PageLayout hasFilters>
      <BatchActionBar />
      <CallToActionBar />
      <Box
        css={{
          display: 'flex',
          overflow: 'hidden',
          height: '100%',
          width: '100%',
        }}
      >
        <JobTable
          pageName="staffingWork"
          onRowsPerPageChange={(value) => {
            dispatch(setStaffingWorkRowsPerPage(value));
          }}
          gridApiRef={gridApiRef}
          hasFilters
        />
        <Drawers gridApiRef={gridApiRef} />
        <FilterDrawer defaultFilters={DEFAULT_SELECTED_FILTERS} />
      </Box>
    </PageLayout>
  );
}

export default HomeContainer;
